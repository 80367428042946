import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '180px',
  borderRadius:"8px",
};

const GoogleMapView = ({ latitude, longitude, zoom = 13 }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'dab5d4e7c25716fb',
    googleMapsApiKey: "AIzaSyAC4adI5_5XusAtEBJkleRlvVouqpWnAVw"
  });

  const center = {
    lat: latitude,
    lng: longitude
  };

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker position={center} />
    </GoogleMap>
  ) : <div>Loading...</div>;
};

export default GoogleMapView;