import * as React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/system';
import { CssTransition } from '@mui/base/Transitions';
import { PopupContext } from '@mui/base/Unstable_Popup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as projectActions from "../../../../store/project";
import sort from '../../../../assets/sort.svg';

export default function Sort() {
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    deals: false,
    ascending: false,
    descending: false,
    all: false
  });

  const handleChange = async(event) => {
    const { name, checked } = event.target;
    
    // if (name === 'all') {
    //   setState(prevState => ({
    //     ...prevState,
    //     deals: checked,
    //     ascending: checked,
    //     descending: checked,
    //     all: checked
    //   }));
    // } else {
    //   setState(prevState => ({
    //     ...prevState,
    //     [name]: checked,
    //     all: prevState.deals && prevState.ascending && prevState.descending && checked
    //   }));
    // }

    if (checked) {
      // Uncheck all options first, then check the selected one
      setState(prevState => ({
        deals: false,
        ascending: false,
        descending: false,
        [name]: true
      }));
  
      // Dispatch the sort action with the selected option
      const searchresult = await dispatch(projectActions.postSort(name));
    } else {
      // If the user is trying to uncheck the only checked option, prevent it
      setState(prevState => {
        const checkedCount = Object.values(prevState).filter(Boolean).length;
        if (checkedCount === 1 && prevState[name]) {
          return prevState; // Don't allow unchecking the last option
        }
        return {
          ...prevState,
          [name]: false
        };
      });
    }
    const searchresult = await dispatch(projectActions.postSort(name));
  };

  const { deals, ascending, descending, all } = state;
  const createHandleMenuClick = (menuItem) => {
    return () => {
      console.log(`Clicked on ${menuItem}`);
    };
  };

  return (
    <Box sx={{ height: "56px" }} >
      <Dropdown >
        <MenuButton><Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1 }}><img src={sort} height="24px" /> Sort By </Box></MenuButton>
        <Menu slots={{ listbox: AnimatedListbox }}>
          <FormGroup sx={{ padding: "4px 16px" }}>
            <FormControlLabel
              control={
                <Checkbox checked={deals} onChange={handleChange} name="deals" />
              }
              label="Best deals"
            />
            <FormControlLabel
              control={
                <Checkbox checked={ascending} onChange={handleChange} name="ascending" />
              }
              label="Funds Ascending"
            />

            <FormControlLabel
              control={
                <Checkbox checked={descending} onChange={handleChange} name="descending" />
              }
              label="Funds Descending"
            />
{/* 
            <FormControlLabel
              control={
                <Checkbox checked={all} onChange={handleChange} name="all" />
              }
              label="All"
            /> */}
          </FormGroup>
        </Menu>
      </Dropdown>
    </Box>
  );
}

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#99CCF3',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E6',
  700: '#0059B3',
  800: '#004C99',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Listbox = styled('ul')(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  z-index: 1;

  .closed & {
    opacity: 0;
    transform: scale(0.95, 0.8);
    transition: opacity 200ms ease-in, transform 200ms ease-in;
  }
  
  .open & {
    opacity: 1;
    transform: scale(1, 1);
    transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
  }

  .placement-top & {
    transform-origin: bottom;
  }

  .placement-bottom & {
    transform-origin: top;
  }
  `,
);

const AnimatedListbox = React.forwardRef(function AnimatedListbox(props, ref) {
  const { ownerState, ...other } = props;
  const popupContext = React.useContext(PopupContext);

  if (popupContext == null) {
    throw new Error(
      'The `AnimatedListbox` component cannot be rendered outside a `Popup` component',
    );
  }

  const verticalPlacement = popupContext.placement.split('-')[0];

  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName="open"
      exitClassName="closed"
    >
      <Listbox {...other} ref={ref} />
    </CssTransition>
  );
});

AnimatedListbox.propTypes = {
  ownerState: PropTypes.object.isRequired,
};

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &.${menuItemClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${menuItemClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[50]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }
  `,
);

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 12px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
    outline: none;
  }
  `,
);