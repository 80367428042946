export const AUTH_TOKEN = "/authorization/token/";
export const REVOKE_TOKEN = "/authorization/revoke_token/";
export const USER_DATA = "/authorization/user/";
export const SIGNUP = "/authorization/signup/";
export const USERS_DATA = "/authorization/users/";
export const GET_OTP = "/authorization/logininitiator/";
 

 
//Gen AI 
export const INFORMATION_DEVELOPER_GEN_AI = '/inv/gen-ai-response/';

export const CONTACT_Us = '/appeglvdKSlu26wb6/tblTc6UUVvC4QyWxB';
export const REQUEST_DOCUMENTS = '/util/request-document';
export const POST_INTERESTED = '/util/iam-interested/';
export const AM_I_INTERESTED = '/util/ami-interested/';
export const INVITE_USER = '/util/invite-user/';
export const GET_TASKLIST = '/util/all-task/';
export const POST_TASKLIST = '/util/add-task/';
export const ADD_TO_TIMELINE = '/util/add-to-timeline/';
export const ALL_TIMELINE = '/util/all-timeline/';
export const ALL_DOCUMENTS = '/util/all-doc/';
export const INFORMATION_DEVELOPER ='/inv/investor-dashboard/';

export const FETCH_ALL_PROJECT =  '/refy/fetch-all-project';

export const AI_INTERACTIVE_MESSAGE = '/ai/interactive-message';
export const LOG_OUT = '/ai/clear-session?uuid=';
export const AI_ANALYTICS = 'ai/analytics?uuid=';